import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Rect" description="Measures a DOM node's bounding box in React" mdxType="SEO" />
    <h1 {...{
      "id": "rect"
    }}>{`Rect`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#userect" mdxType="TOCLink">useRect</TOCLink>
    <TOCLink href="#rect-1" mdxType="TOCLink">Rect</TOCLink>
  </TOCList>
    </TOC>
    <p>{`Measures DOM elements (aka. bounding client rect). See also `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect"
      }}>{`Element.getBoundingClientRect()`}</a></p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/rect`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/rect`}</inlineCode>{`. Then import the component or hook that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/rect
# or
yarn add @reach/rect
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Rect, { useRect } from "@reach/rect";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "userect"
    }}>{`useRect`}</h3>
    <p><inlineCode parentName="p">{`function useRect<T extends Element>(nodeRef: React.RefObject<T>, options?: { observe?: boolean, onChange?: (rect: DOMRect) => void }): null | DOMRect`}</inlineCode></p>
    <p>{`Hook that observes and returns the measurements (ClientRect) of a DOM element. Pass it the ref that is placed on the element to be measured.`}</p>
    <h4 {...{
      "id": "userect-options"
    }}>{`useRect Options`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#userect-observe"
            }}><inlineCode parentName="a">{`observe`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#userect-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "userect-observe"
    }}>{`useRect observe`}</h5>
    <p>{`Tells `}<inlineCode parentName="p">{`useRect`}</inlineCode>{` whether or not to observe changes to the position of the node. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`This is typically used for elements that pop over other elements. You generally don't need to observe all of the time—only when the popup is active.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`observe`}</inlineCode>{` is false, the element's `}<inlineCode parentName="p">{`DOMRect`}</inlineCode>{` will no longer be observed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  const [observe, setObserve] = React.useState(true);
  // your own ref
  const ref = React.useRef();

  // pass it in to be observered
  const rect = useRect(ref, { observe });

  return (
    <div>
      <button onClick={() => setObserve(!observe)}>
        {observe ? "Stop" : "Start"} observing
      </button>
      <pre>{JSON.stringify(rect, null, 2)}</pre>
      <div
        // and then place the ref
        ref={ref}
        contentEditable
        style={{
          display: "inline-block",
          padding: 10,
          border: "solid 1px",
        }}
        dangerouslySetInnerHTML={{
          __html: "Edit this to change the size!",
        }}
      />
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "userect-onchange"
    }}>{`useRect onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?(rect: DOMRect): void`}</inlineCode></p>
    <p>{`Calls back whenever the `}<inlineCode parentName="p">{`rect`}</inlineCode>{` of the element changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example() {
  const ref = React.useRef();
  useRect(ref, {
    onChange(rect) {
      console.log(rect);
    },
  });
  return <div ref={ref} />;
}
`}</code></pre>
    <h3 {...{
      "id": "rect-1"
    }}>{`Rect`}</h3>
    <p>{`Render prop component for use in class components to observe element measurements.`}</p>
    <h4 {...{
      "id": "rect-props"
    }}>{`Rect Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#rect-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#rect-observe"
            }}><inlineCode parentName="a">{`observe`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#rect-onchange"
            }}><inlineCode parentName="a">{`onChange`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "rect-children"
    }}>{`Rect children`}</h5>
    <p><inlineCode parentName="p">{`children(args: { rect: DOMRect | null; ref: React.Ref<any> }): JSX.Element`}</inlineCode></p>
    <p>{`A function that calls back to you with a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` to place on an element and the `}<inlineCode parentName="p">{`rect`}</inlineCode>{` measurements of the dom node.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: On the first render `}<inlineCode parentName="p">{`rect`}</inlineCode>{` will be `}<inlineCode parentName="p">{`null`}</inlineCode>{` because we can't measure a node that has not yet been rendered. Make sure your code accounts for this.`}</p>
    <h5 {...{
      "id": "rect-observe"
    }}>{`Rect observe`}</h5>
    <p><inlineCode parentName="p">{`observe?: boolean`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#userect-observe"
      }}><inlineCode parentName="a">{`useRect`}</inlineCode>{`'s `}<inlineCode parentName="a">{`observe`}</inlineCode>{` option`}</a>{`. While observing, the `}<inlineCode parentName="p">{`children`}</inlineCode>{` render prop may call back very quickly (especially while scrolling), so it can be important for performance to avoid observing when you don't need to. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Rect observe={false}>
  {({ rect, ref }) => (
    <div ref={ref}>
      <div>Will not measure the element when false</div>
      <div>
        Edit this code and change it to <code>true</code>
      </div>
      <pre>{JSON.stringify(rect, null, 2)}</pre>
    </div>
  )}
</Rect>
`}</code></pre>
    <h5 {...{
      "id": "rect-onchange"
    }}>{`Rect onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?(rect: DOMRect): void`}</inlineCode></p>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#userect-onchange"
      }}><inlineCode parentName="a">{`useRect`}</inlineCode>{`'s `}<inlineCode parentName="a">{`onChange`}</inlineCode>{` option`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Rect onChange={rect => console.log(rect)}/>
  {({ rect, ref }) => (
    <div ref={ref}/>
  )}
</Rect>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      